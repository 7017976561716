import axios from '@/axios';

class CvService {

    achievements(candidateId) {
        return axios.get(`/cv/${candidateId}/achievements`)
    }

    updateAchievements(candidateId, data) {
        return axios.post(`/cv/${candidateId}/achievements`, data)
    }

    certificates(candidateId) {
        return axios.get(`/cv/${candidateId}/certificates`)
    }

    updateCertificates(candidateId, data) {
        return axios.post(`/cv/${candidateId}/certificates`, data)
    }

    courses(candidateId) {
        return axios.get(`/cv/${candidateId}/courses`)
    }

    updateCourses(candidateId, data) {
        return axios.post(`/cv/${candidateId}/courses`, data)
    }

    education(candidateId) {
        return axios.get(`/cv/${candidateId}/education`)
    }

    updateEducation(candidateId, data) {
        return axios.post(`/cv/${candidateId}/education`, data)
    }

    experience(candidateId) {
        return axios.get(`/cv/${candidateId}/experience`)
    }

    updateExperience(candidateId, data) {
        return axios.post(`/cv/${candidateId}/experience`, data)
    }

    hobbies(candidateId) {
        return axios.get(`/cv/${candidateId}/hobbies`)
    }

    updateHobbies(candidateId, data) {
        return axios.post(`/cv/${candidateId}/hobbies`, data)
    }

    internship(candidateId) {
        return axios.get(`/cv/${candidateId}/internship`)
    }

    updateInternship(candidateId, data) {
        return axios.post(`/cv/${candidateId}/internship`, data)
    }

    languages(candidateId) {
        return axios.get(`/cv/${candidateId}/languages`)
    }

    updateLanguages(candidateId, data) {
        return axios.post(`/cv/${candidateId}/languages`, data)
    }

    skills(candidateId) {
        return axios.get(`/cv/${candidateId}/skills`)
    }

    updateSkills(candidateId, data) {
        return axios.post(`/cv/${candidateId}/skills`, data)
    }
}

export const cvService = new CvService();